<template>
  <v-container class="pa-0 pbxLayoutBg" fluid fill-height>
    <div class="pbx-bg"></div>
    <v-row no-gutters align="center" justify="center">
      <v-card
        class="pbxBg pbxConnect"
        :class="isXs ? 'pa-15 pa-sm-20' : 'pa-7'"
        :max-width="isMobile ? '598' : '90%'"
        outlined
        flat
      >
        <v-row class="container-pbx-info pb-10 pb-sm-17" no-gutters>
          <v-col cols="12">
            <div
              class="d-flex rounded connect-pbx-info"
              :class="isXs ? 'px-10 py-8 pa-sm-15' : 'px-7 py-5'"
            >
              <v-row align="center" no-gutters>
                <v-col  cols="2" class="d-none d-sm-block">
                  <v-icon color="pbxAlarmIcon" size="70">$alarmIcon</v-icon>
                </v-col>

                <v-col class="pl-sm-16" cols="12" sm="10">
                  <div
                    class="pbxTextInfo--text"
                    :class="isMobile ? 'font-xs' : 'font-xs-mobile'"
                  >
                    This Account has been blocked. Please contact Dialics support support@dialics.com for information regarding account status.
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="d-flex align-center flex-wrap" cols="12 pt-8" v-if="!!accounts && accounts.length > 1">
            <span class="font-normal nameField--text pr-10">Select another account</span>

            <AutocompleteField
              :value="currentAccount"
              :items="availableAccounts"
              item-value="slug"
              item-text="company"
              @change="changeAccount"
            />
          </v-col>

          <v-col class="d-flex justify-end" cols="12 pt-8">
            <div class="font-xs textLink--text pr-10 py-6">
              <TextLink @click="logout">Logout</TextLink>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import OutlinedButton from '@/components/buttons/OutlinedButton'
import ActionButton from '@/components/buttons/ActionButton'
import TextLink from '@/components/links/TextLink'
import AutocompleteField from '@/components/select/AutocompleteField'

import { mapState, mapActions } from 'vuex'
import { Types as authTypes } from '@/store/modules/auth'

export default {
  name: 'AccountDisabled',
  components: { OutlinedButton, ActionButton, TextLink, AutocompleteField },
  data: () => ({
    currentAccount: null,
  }),
  computed: {
    ...mapState({
      accountId: state => state.auth.accountId,
      accounts: state => state.auth.accounts,
    }),
    isMobile() {
      return this.$vuetify.breakpoint.width > 650
    },
    isXs() {
      return this.$vuetify.breakpoint.width > 360
    },
    // currentAccount: {
    //   get() {
    //     return this.accountId
    //   },
    //   set(acc) {
    //     this.checkUserSubAccount(acc)
    //   },
    // },
    availableAccounts() {
      return this.accounts.filter(acc => acc.slug !== this.accountId)
    }
  },
  methods: {
    ...mapActions({
      logout: authTypes.actions.LOG_OUT,
      checkUserSubAccount: authTypes.actions.CHECK_USER_SUB_ACCOUNT,
    }),
    goToRenew() {

    },
    changeAccount(acc) {
      this.checkUserSubAccount(acc)
      // this.currentAccount = acc
    }
  },
}
</script>

<style lang="scss">
.container.pbxLayoutBg.container--fluid.fill-height {
  position: relative;
  overflow: hidden;

  .pbx-bg {
    width: 100%;
    height: 109vh;
    position: absolute;
    left: calc(40vw - 785px);
    top: 53%;
    transform: translateY(-50%) scale(1.1);
    background-image: url(/images/pbxBg.png);
    background-position: left center;
  }

  & .pbxConnect {

    &.theme--light {
      box-shadow: 0 8px 40px rgba(23, 10, 60, 0.28)!important;
    }

    &.theme--dark {
      box-shadow: 0 8px 40px 0 rgba(0, 0, 0, 0.68)!important;
    }


    .form-pbx {

      .pbx-field {

        & > .v-input {
          color: #FFFFFF!important;

          &.theme--light:not(.input-number-default) {
            & > .v-input__control {
              & > .v-input__slot {
                border: 1px solid #C9A9F5!important;

                &.inputFocus {
                  background-color: #8363E0!important;
                }
              }
            }
          }

          & > .v-input__control {
            & > .v-input__slot {
              input {
                color: #FFFFFF!important;
              }
            }
          }

          &.error--text {
            & > .v-input__control {
              & > .v-input__slot {
                border: 1px solid var(--v-pink-base)!important;
              }
            }
          }
        }

        & > .cancel-btn {
          border-color: var(--v-pbxCancelBtnBorder-base)!important;

          &:hover {
            border-color: var(--v-pink-base)!important;

            & > .v-btn__content {
              span {
                color: #FFFFFF!important;
              }
            }
          }

          & > .v-btn__content {
            span {
              color: #F4F0F9!important;
            }
          }
        }

        & > .action-btn {
          &.theme--light:hover {
            background-color: var(--v-actionButtonForDarkHoverBg-base)!important;

            & > .v-btn__content {
              span {
                color: var(--v-pbxActionBtnTextHover-base)!important;
              }
            }
          }
          &.theme--dark:hover {
            background-color: var(--v-actionButtonHoverBg-base)!important;

            & > .v-btn__content {
              span {
                color: var(--v-pbxActionBtnTextHover-base)!important;
              }
            }
          }

          background-color: var(--v-pbxActionBtnBg-base)!important;

          & > .v-btn__content {
            span {
              color: var(--v-pbxActionBtnText-base)!important;
            }
          }
        }
      }
    }
  }

  & .container-pbx-info {

    & .connect-pbx-info{
      border: 1px solid var(--v-pbxInfoBorder-base);
    }
  }
}

@media screen and (max-width: 1280px) {
  .container.pbxLayoutBg.container--fluid.fill-height {

    .pbx-bg {
      width: 150%;
      transform: translateY(-50%) scale(1);
    }
  }
}

@media screen and (max-width: 760px) {
  .container.pbxLayoutBg.container--fluid.fill-height {

    .pbx-bg {
      left: -150px;
    }
  }
}

// ipad pro
@media only screen and (min-device-width: 1024px) and (max-device-height: 1366px) and (-webkit-min-device-pixel-ratio: 2)  and (orientation: portrait)  {
  .container.pbxLayoutBg.container--fluid.fill-height {

    .pbx-bg {
      width: 100%;
      top: 52%;
      background-position: -265px;
      background-size: 115% 100%;
      height: 100vh;
      left: calc(40vw - 590px);
      transform: translateY(-50%) scale(1.1);
    }
  }
}
</style>